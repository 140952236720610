import React, { useEffect } from "react"
import dynamic from "next/dynamic"
import { useGlobalContext } from "../../context/layout-context"

const VideoInModal = dynamic(() => import("./video-in-modal"), { ssr: false })

function VideoInModalComponent() {
  const { globalState, updateGlobalState } = useGlobalContext()
  const { videoId, videoSrc, modalVideoOpen } = globalState || {}

  useEffect(() => {
    if (videoId && videoSrc) {
      updateGlobalState({
        modalVideoOpen: true,
      })
    } else {
      updateGlobalState({
        modalVideoOpen: false,
      })
    }

    return () => {
      updateGlobalState({
        modalVideoOpen: false,
        videoId: null,
        videoSrc: null,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.StrictMode>
      {videoSrc && videoId ? (
        <VideoInModal
          open={modalVideoOpen}
          // VIDEO ID SOURCE
          video={videoId}
          // VIDEO SOURCE "YOUTUBE OR VIMEO VALUE"
          source={videoSrc}
        />
      ) : null}
    </React.StrictMode>
  )
}

export default VideoInModalComponent
